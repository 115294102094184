
import { Component, Vue, Prop } from "vue-property-decorator";
import EducationCardTemplate from "@/components/EducationCardTemplate.vue";
import ICourse from "@/types/course";
import store from "@/store";

@Component({
  components: { EducationCardTemplate },
})
export default class CourseCard extends Vue {
  @Prop({ required: true }) private readonly course!: ICourse;

  private get courseLink() {
    return `/courses/${this.course.id}`;
  }

  private exportLink() {
    const { token } = store.getters
    fetch(`/api/exportLessonsFile?courseID=${this.course.id}`, { headers: { "Token": token } })
      .then(res => {
        return res.blob()
      })
      .then(blob => {
        var file = window.URL.createObjectURL(blob);
        window.location.assign(file);
      });
  }

  private onEdit() {
    this.$router.push(`/courses-create/${this.course.id}`);
  }
}
