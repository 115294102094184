
import { Component, Vue } from "vue-property-decorator";
import CourseCard from "@/components/CourseCard.vue";
import ICourse from "@/types/course";
import { getAllCourses } from "@/api/api";
import TheLoader from "@/components/TheLoader.vue";

@Component({
  components: { TheLoader, CourseCard },
})
export default class Courses extends Vue {
  private courses: ICourse[] = [];
  private loading = true;

  private async loadAllCourses() {
    try {
      this.courses = await getAllCourses();
    } catch (e) {
      console.error(e);
    }
  }

  async mounted(): Promise<void> {
    await this.loadAllCourses();
    this.loading = false;
  }

  private get canManageCourses() {
    return this.$store.getters.canManageCourses;
  }

  private get notFoundText() {
    return `Курсов пока нет. ${
      this.canManageCourses ? "Создать?" : "Обратитесь к администратору."
    }`;
  }
}
